import './App.css';
import Marquee from "react-marquee-slider";

function App() {
  const techs = [
    "DevOps",
    ".Net Core",
    "Asp .Net MVC",
    "C#",
    "Java",
    "Javascript",
    "ReactJS",
    "Extjs",
    "JQuery",
    "Angular",
    "TypeScript",
    "Bootstrap",
    "Node.js",
    "AWS",
    "Azure",
    "Google Cloud",
    "SQL Server",
    "MySQL",
    "Oracle",
    "Entity Framework",
    "Nhibernate",
    "NPoco",
    "Docker",
    "XUnit, nUnit",
    "Selenium",
    "Cypress"
  ];
  return (
    <div>
      <div className="container-fluid ">
        <div className='row bg-white '>
          <div className='col shadow'>
            <div className='p-3 border-secondary'>
              <img src='/logo-icon.png' width={70} className='d-inline-block' alt='logo' />
              <h4 className='d-inline-block fs-4'>Byone Software Technologies Ltd</h4>
            </div>
          </div>
        </div>
        <div className='row main'>
          <div className='col'>
            <div className="container">
              <div className="row py-5">
                <div className="col-lg-10 col-md-12 col-xl-8 p-5 bg-dark bg-gradient bg-opacity-50 text-white rounded shadow">
                  <div className="block">
                    <span className="text-uppercase text-sm letter-spacing fs-4">The most powerful Solution</span>
                    <h1 className="mb-3 mt-3">Digital experience with Byone Software</h1>
                    <p className="my-5">We specialize in crafting cutting-edge web applications tailored to meet your unique business needs.
                      As a software development consultant company, we blend creativity with technical expertise to deliver innovative solutions that drive your business forward.</p>
                  </div>
                </div>
              </div>
              <div className='row pt-5'>
                <div className='col position-relative'>
                  <div className="card-group position-absolute top-100 start-50 translate-middle">
                    <div className='card service-card text-center'>
                      <div className="card-body position-relative">
                        <div className="card-title position-absolute start-50 top-50 translate-middle fw">Effective Software Development</div>
                      </div>
                    </div>
                    <div className='card service-card text-center'>
                      <div className="card-body position-relative">
                        <div className="card-title position-absolute start-50 top-50 translate-middle">Analyse Your Architecture Design</div>
                      </div>
                    </div>
                    <div className='card service-card text-center'>
                      <div className="card-body position-relative">
                        <div className="card-title position-absolute start-50 top-50 translate-middle">SaaS & Cloud Services</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row pt-5'>
          <div className='col'>
            <div className='container'>
              <div className='row pt-5'>
                <div className="col px-5">
                  <div className="block">
                    <span className="text-uppercase text-sm letter-spacing fs-6">WHY CHOOSE US</span>
                    <h5 className="mb-3 mt-3">We help you to make work easy</h5>
                    <p className="my-5">Our seasoned team of professionals brings years of industry experience, delivering tailored solutions to meet your unique business needs.
                      From innovative software development to strategic consulting, we're dedicated to driving your success through cutting-edge technology and unparalleled customer service.</p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col px-5">
                  <span className="text-uppercase text-sm letter-spacing fs-6">OUR TECH STACK</span>
                  <div className='my-3 bg-light bg-gradient rounded shadow-sm'>
                    <Marquee
                      key={Math.random()}
                      minScale={0.7}
                      resetAfterTries={100}
                    >
                      {techs.map((word, index) => (
                        <div key={index} style={{ padding: "20px 40px" }}>
                          {word}
                        </div>
                      ))}
                    </Marquee>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col px-5">
                  <div className="block">
                    <span className="text-uppercase text-sm letter-spacing fs-6">CONTACT US</span>
                    <h5 className="mb-3 mt-3">Get In Touch</h5>
                    <p className="my-1">Email: info@byone.co.uk</p>
                    <p className="my-1">Phone: (+44) 7387443859</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <div className="col">
            <div className="mb-3 mb-md-0 text-body-secondary small">© 2024 Byone Software Technologies Ltd. is a limited company registered in England.</div>
            <div className='text-body-secondary small'>Company number 11880250 / VAT number 350891294</div>
            <div className='text-body-secondary small'>565 Green Lanes, Haringey, London, England, N8 0RL</div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
